import * as Sentry from "@sentry/nextjs"
import { getLiveDateRange } from "../utils/helpers"
import Error from "next/error"

export async function getServerSideProps({ resolvedUrl }) {
  const domainBrand = process.env.NEXT_PUBLIC_BRAND

  try {
    const response = await fetch(`${process.env.LIGHOST}/api/v1/campaigns/?brand=${domainBrand}&status=active`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.LIGAUTH}`
      }
    })

    const campaigns = await response.json()
    const defaultCampaign = campaigns.find(
      (campaign) =>
        campaign.brand === domainBrand && getLiveDateRange(campaign.dates)
    )
    const { id, brand, slug } = defaultCampaign

    const noCampaignAndNotGiftLigonierOrg =
      !defaultCampaign && domainBrand !== "Ligonier Ministries"

    const isNotMinistryPartnerCampaign = brand != "Ministry Partners"
    const domainDoesNotMatchCampaignBrand = domainBrand != brand

    if (noCampaignAndNotGiftLigonierOrg) {
      // if there is no campaign and we are on a non-LM site, go to the fallback URL
      return {
        redirect: {
          destination: `${process.env.NEXT_PUBLIC_FALLBACK_HOST}${resolvedUrl}`,
          permamnent: false
        }
      }
    } else if (
      isNotMinistryPartnerCampaign &&
      domainDoesNotMatchCampaignBrand
    ) {
      // Otherwise if there is a campaign that is not MP and the env var for brand does not match the campaign brand, redirect to the
      // proper "/" page for the host for the campaign brand.
      return {
        redirect: {
          destination: `${process.env.NEXT_PUBLIC_FALLBACK_HOST}/${id}/${slug}${resolvedUrl}`,
          permamnent: false
        }
      }
    }
    // otherwise reroute to the id/slug route
    else {
      return {
        redirect: {
          destination: `/${id}/${slug}${resolvedUrl}`,
          permanent: false
        }
      }
    }
  } catch (error) {
    console.error("home page getServerSideProps error", error)
    Sentry.captureException(error)

    return {
      props: {
        errorCode: 500
      }
    }
  }
}

export default function Home({ errorCode }) {
  if (errorCode) {
    return <Error statusCode={errorCode} />
  }
  
  return null
}
